import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const SocialProofSection = styled.section`
  width: 100%;
  height: 100%;
  padding: 32px 16px;
  background: linear-gradient(87deg, #3b9eff 0%, #53dbff 100%);
`;

const SocialProofContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  flex-direction: column;

  p {
    color: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    word-wrap: break-word;
  }

  ${media.lg`
   flex-direction: row;
     padding: 64px 0px;
       gap: 96px;
  `}
`;

const QuoteContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px;
  display: inline-flex;

  ${media.lg`
    width: 50%;
  `}
`;

const QuoteAuthorContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: inline-flex;

  img {
    border-radius: 8px;
    max-width: 56px;
    max-height: 56px;
  }
`;

const AuthorNameAndRoleContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;

  span {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: white;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }
`;

const LogosContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  gap: 8px;

  ${media.lg`
     padding-top: 24px;
    padding-bottom: 24px;
    column-gap: 16px;
    row-gap: 10px;
  `}
`;

export {
  SocialProofSection,
  SocialProofContainer,
  QuoteContainer,
  QuoteAuthorContainer,
  AuthorNameAndRoleContainer,
  LogosContainer,
};
