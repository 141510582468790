import * as React from "react";
import { FormEvent, useEffect, useRef, useState } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { getLocalStorageItem, setLocalStorageItem } from "utils/local-storage";
import { DEMO_EMAIL_KEY, DEMO_NAME_KEY } from "constants/local-storage";
import PlainHeader from "components/ui/base/PlainHeader";
import { useSendDemoForm } from "networking/useForms";
import useEventLeadGenerated from "events/useEventLeadGenerated";
import { getTracking } from "utils/tracking";
import { navigate } from "gatsby";
import FormWithBulletpoints from "components/ui/base/FormWithBulletpoints";
import {
  AuthorNameAndRoleContainer,
  LogosContainer,
  QuoteAuthorContainer,
  QuoteContainer,
  SocialProofContainer,
  SocialProofSection,
} from "styles/demo.style";
import StaticImg from "components/core/StaticImg";
import Icon from "components/core/Icon";
import { PageContainer } from "styles/global.style";
import VideoPreview from "components/ui/base/VideoPreview";

interface IndexPageProps {
  location: Location;
}

declare var window: {
  customerly: any;
};

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  const [existingName, setExistingName] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);
  const sendForm = useSendDemoForm();

  const { fireEvent } = useEventLeadGenerated();

  const handleSubmit = async (event: FormEvent) => {
    if (formRef.current) {
      event.preventDefault();

      const formData = new FormData(formRef.current);

      const fields = {
        name: formData.get("name"),
        lastName: formData.get("lastName"),
        workEmail: formData.get("workEmail"),
        ticketVolume: formData.get("ticketVolume"),
        supportAgents: formData.get("supportAgents"),
      };

      setLocalStorageItem(DEMO_NAME_KEY, fields.name);

      const currentTracking = getTracking();

      try {
        if (
          typeof fields.workEmail === "string" &&
          typeof fields.lastName === "string" &&
          typeof fields.ticketVolume === "string" &&
          typeof fields.supportAgents === "string" &&
          typeof fields.name === "string"
        ) {
          const response = await sendForm(fields.workEmail, {
            name: fields.name,
            last_name: fields.lastName,
            ticket_volume: fields.ticketVolume,
            supportAgents: fields.supportAgents,
            contact_source: "demo form",
            ...currentTracking,
          });

          setLocalStorageItem(DEMO_EMAIL_KEY, fields.workEmail);

          window.customerly.registerLead(fields.workEmail, {
            name: fields.name,
            last_name: fields.lastName,
            ticket_volume: fields.ticketVolume,
            supportAgents: fields.supportAgents,
            contact_source: "demo form",
            ...currentTracking,
          });

          if (response?.demo === true) {
            fireEvent({}, () => {
              navigate("/demo/book-sales");
            });
          } else if (response?.demo === false) {
            fireEvent({}, () => {
              navigate("/live-demo/ai");
            });
          }
        }

        // Close the modal
      } catch (error) {
        console.log("There was an error submitting the form:", error);
        fireEvent({}, () => {
          navigate("/demo/book-sales");
        });
      }
    }
  };

  useEffect(() => {
    const name = getLocalStorageItem(DEMO_NAME_KEY) || "";
    if (name) {
      setExistingName(name);
    }
  });

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        withCta: false,
        mobileCta: false,
      }}
    >
      <SEO
        title={t("pages.index.seo.title")}
        description={t("pages.index.seo.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-behavioural-chats-emails-and-surveys-saas.jpg",
          alt: "Customer Operating System for SaaS",
        }}
      />

      <PlainHeader
        preTitle={"Free demo for saas companies"}
        title={
          "<u>Give Us 30 Mins</u> & We’ll Show You How To Automate 60% Of Your Tickets"
        }
        description={
          "Never worry about spikes, slowing down response times or overwhelming your support team ever again!"
        }
        maxWidth={960}
      />

      <FormWithBulletpoints
        title={"In This Free Demo, We’ll Show You:"}
        bulletpoints={[
          "All the ways you can use automation without sacrificing support quality.",
          "Answer any questions, feel free to invite your support team!",
          "How to do all of this <u>without having to redo your knowledge base</u>.",
        ]}
      />

      <SocialProofSection>
        <PageContainer>
          <SocialProofContainer>
            <QuoteContainer>
              <Icon type={"quote"} size={36} />
              <p>
                I was shocked that even during the demo they found more
                opportunities to optimize our support than we could ever
                imagine. The best part is they helped us get set up within 3
                days!
              </p>
              <QuoteAuthorContainer>
                <StaticImg alt={"Daniele"} src={"people/daniele-ratti.jpeg"} />
                <AuthorNameAndRoleContainer>
                  <span>Daniele Ratti</span>
                  <p>CEO @ TeamSystem Fatture in Cloud</p>
                </AuthorNameAndRoleContainer>
              </QuoteAuthorContainer>
            </QuoteContainer>
            <LogosContainer>
              <StaticImg
                alt={"TS"}
                src={"customers/white/TS.png"}
                maxWidth={250}
              />

              <StaticImg
                alt={"Nibol"}
                src={"customers/white/nibol.png"}
                maxWidth={250}
              />
              <StaticImg
                alt={"notjustanalytics"}
                src={"customers/white/notjustanalytics.png"}
                maxWidth={250}
              />
              <StaticImg
                alt={"Paykickstart"}
                src={"customers/white/Paykickstart.png"}
                maxWidth={250}
              />
              <StaticImg
                alt={"TS"}
                src={"/customers/white/TS.png"}
                maxWidth={250}
              />
              <StaticImg
                alt={"cookiescript"}
                src={"customers/white/CookieScript.png"}
                maxWidth={250}
              />
              <StaticImg
                alt={"ePayco"}
                src={"customers/white/ePayco.png"}
                maxWidth={250}
              />
            </LogosContainer>
          </SocialProofContainer>
        </PageContainer>
      </SocialProofSection>

      <VideoPreview
        id={"funnel-builder"}
        title={
          "We Can Even Show You How To Get This Implemented <u>This Week</u>"
        }
        paragraph={t(
          "This is a sneak peek of how in depth we can get. We can show you how you could build the specific use cases you’d want during the demo."
        )}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/demo/demo-preview.mp4",
          },
        ]}
      />
    </GlobalWrapper>
  );
};
